<template>
  <div class="main">
    <Top ref="topRef" />
    <div class="top">
      <van-nav-bar
        @click-left="onClickLeft"
        @click-right="onClickRight"
        :title="lang.v.SwitchAccount"
        left-arrow
        :right-text="text"
      ></van-nav-bar>
    </div>
    <div>
      <div
        class="box"
        v-for="(item, index) in allUsers"
        :key="index"
        @click="login_exchange(index)"
      >
        <div class="box_left">
          <div class="box_left_sculpture">
            <img :src="item?.avatar" alt="" />
          </div>
          <div class="box_left_infos">
            <div class="name">{{ item?.nickname }}</div>
            <div class="userid">UID: {{ item?.userId }}</div>
          </div>
        </div>
        <div class="box_right" v-if="item.userId == userInfo.userId">
          <div class="status"></div>
          <div class="text">{{ lang.v.Using }}</div>
        </div>
        <div
          class="box_right"
          v-if="item.userId == userInfo.userId || !clear_user ? false : true"
        >
          <div class="clear" @click.stop="clear_one(index)">Clear</div>
        </div>
        <div class="overlay" v-if="showOverlay && index === 0"></div>
      </div>
      <div
        class="box"
        @click="add_zhanghao"
        v-if="clear_user == true ? false : true"
      >
        <div class="box_left">
          <div class="add">
            <img src="@/assets/img/add_zhanghao.png" alt="" />
          </div>
          <div class="text">{{ lang.v.AddAcount }}</div>
        </div>
      </div>
    </div>
    <van-overlay :show="show">
      <div class="wrapper"><van-loading type="spinner" size="0.50rem" /></div>
    </van-overlay>
    <van-dialog
      v-model:show="deleted"
      show-cancel-button
      :confirmButtonText="lang.v.confirm"
      :cancelButtonText="lang.v.cancel"
      @confirm="confirm"
      confirm-button-color="rgba(255, 0, 0, 1)"
    >
      <div class="content">{{ lang.v.ClearAccount }}</div>
    </van-dialog>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import Top from '@/components/Top/top.vue';
import router from '@/router';
import { lang } from '@/lang';
import { personal } from '@/api/fetcher';
import dataCenter from '@/dataCenter';
import { tokenController } from '@/manager/token';
const clear_user = ref(false);
const showOverlay = ref(false);
const text = ref(lang.v.Edit);
const show = ref(false);
const topRef = ref(null);
const onClickLeft = () => {
  router.go(-1);
};
const login_exchange = async index => {
  if (showOverlay.value) return;
  show.value = true;
  const selectedUser = allUsers.value[index];
  allUsers.value.splice(index, 1);
  allUsers.value.unshift(selectedUser);
  tokenController.update(selectedUser.access_token, selectedUser.refresh_token);
  await personal()
    .then(res => {
      if (res) {
        dataCenter.user.save(res);
        userInfo.value = res;
      }
    })
    .catch(() => {
      dataCenter.allUsers.delecte(selectedUser.userId);
    })
    .finally(() => {
      show.value = false;
    });
  topRef.value.refresh();
};
const deleted = ref(false);
const onClickRight = () => {
  if (text.value == lang.v.Edit) {
    text.value = lang.v.Finish;
    clear_user.value = true;
    showOverlay.value = true;
  } else if (text.value == lang.v.Finish) {
    text.value = lang.v.Edit;
    clear_user.value = false;
    showOverlay.value = false;
  }
};
const clear_one = index => {
  deleted.value = true;
  sessionStorage.setItem(
    'delete_userId',
    JSON.stringify(allUsers.value[index].userId)
  );
};
const confirm = () => {
  const delete_userId = JSON.parse(localStorage.getItem('delete_userId'));
  const index = allUsers.value.findIndex(
    allUsers => allUsers.userId === delete_userId
  );
  if (index == -1) {
    allUsers.value.splice(index, 1);
    localStorage.setItem('allUsers', JSON.stringify(allUsers.value));
  }
};
const add_zhanghao = () => {
  router.push('/login');
};
const allUsers = ref(dataCenter.allUsers.data);
const userInfo = ref(dataCenter.user.data);

onMounted(async () => {
  await personal().then(res => {
    if (res) {
      dataCenter.user.data.save(res);
      userInfo.value = res;
    }
  });
});
</script>
<style lang="less" scoped>
.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f8;
  overflow-y: scroll;
}
.top {
  background-color: rgba(255, 255, 255, 1);
}

:deep(.van-icon) {
  color: black;
}

:deep(.van-nav-bar__text) {
  font-size: 0.14rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #858c9f;
}

:deep(.van-nav-bar) {
  height: 0.25rem;
  padding-top: 0.25rem;

  .van-nav-bar__content {
    height: 0rem;
    padding: 0;

    .van-nav-bar__left {
      // margin-left: -0.8rem;
      .van-badge__wrapper {
        font-size: 0.18rem;
      }
    }

    .van-nav-bar__right {
      .van-nav-bar__text {
        color: #444444 !important;
      }
    }

    .van-nav-bar__title {
      font-size: 0.18rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #444444;
    }
  }
}

.box {
  height: 0.7rem;
  width: 92.5%;
  margin: auto;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0.1rem;
  margin-top: 0.14rem;
  display: flex;
  justify-content: space-between;

  .overlay {
    position: absolute;
    top: 1.25rem;
    left: 50%;
    transform: translate(-50%);
    width: 92.5%;
    height: 0.7rem;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0rem 0.02rem 0.1rem 0rem rgba(0, 0, 0, 0.02);
    border-radius: 0.1rem;
    z-index: 10;
  }

  .box_left {
    display: flex;

    .add {
      width: 0.42rem;
      height: 0.42rem;
      margin-top: 0.14rem;
      margin-left: 0.14rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .text {
      height: 0.7rem;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #444444;
      line-height: 0.7rem;
      margin-left: 0.14rem;
    }

    .box_left_sculpture {
      width: 0.42rem;
      height: 0.42rem;
      // background-color: aqua;
      margin-top: 0.14rem;
      margin-left: 0.14rem;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .box_left_infos {
      margin-top: 0.14rem;
      margin-left: 0.14rem;

      .name {
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #444444;
        line-height: 0.2rem;
      }

      .userid {
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #858c9f;
        line-height: 0.2rem;
        margin-top: 0.04rem;
      }
    }
  }

  .box_right {
    display: flex;
    margin-top: 0.14rem;
    margin-right: 0.14rem;

    .status {
      width: 0.05rem;
      height: 0.05rem;
      border-radius: 50%;
      background-color: rgba(40, 196, 69, 1);
      margin-top: 0.04rem;
      margin-right: 0.06rem;
    }

    .text {
      height: 0.17rem;
      font-size: 0.12rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #444444;
      line-height: 0.14rem;
    }

    .clear {
      height: 0.2rem;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #ff0000;
      line-height: 0.2rem;
      margin-top: 0.12rem;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.content {
  padding-top: 0.1rem;
  margin-left: 0.14rem;
}
</style>
